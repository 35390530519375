@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  guide-viewer,
  app-component-viewer {
    color: mat.get-color-from-palette($foreground, text);

    .mat-tab-label:focus {
      color: mat.get-color-from-palette($foreground, text);
    }
  }

  .docs-api-h3 {
    border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);
  }
}
