@use '@angular/material' as mat;

@use 'sass:map';
@use './app/pages/component-category-list/component-category-list-theme';
@use './app/pages/component-sidenav/component-sidenav-theme';
@use './app/pages/component-viewer/component-viewer-theme';
@use './app/pages/not-found/not-found-theme';
@use './app/shared/carousel/carousel-theme';
@use './app/shared/table-of-contents/table-of-contents-theme';
@use './styles/api-theme';
@use './styles/markdown-theme';
@use './styles/svg-theme';
@use './styles/tables-theme';

// Styles for the docs app that are based on the current theme.
@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $next-theme: mat.define-palette(mat.$red-palette);

  .docs-app-background {
    background: mat.get-color-from-palette($background, background);
  }

  .docs-primary-header {
    background: black;

    h1 {
      color: mat.get-color-from-palette($primary, default-contrast);
    }
  }

  .docs-help-support {
    color: mat.get-color-from-palette($foreground, secondary-text);

    a {
      color: mat.get-color-from-palette($foreground, text);
    }
  }

  .is-next-version {
    background: mat.get-color-from-palette($next-theme, 900) !important;
  }

  @include carousel-theme.theme($theme);
  @include component-category-list-theme.theme($theme);
  @include component-sidenav-theme.theme($theme);
  @include component-viewer-theme.theme($theme);
  @include api-theme.theme($theme);
  @include markdown-theme.theme($theme);
  @include svg-theme.theme($theme);
  @include tables-theme.theme($theme);
  @include not-found-theme.theme($theme);
  @include table-of-contents-theme.theme($theme);
}
