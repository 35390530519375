@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  .docs-carousel-nav.mat-mini-fab {
    background: mat.get-color-from-palette($background, background);
    color: mat.get-color-from-palette($foreground, secondary-text)
  }

}
