html,
body {
  height: 100vh;
}

body {
  font-family: "Roboto","Helvetica Neue Light","Helvetica Neue",Helvetica,Arial,"Lucida Grande",sans-serif;
  margin: 0;
}

.docs-button[md-button], .docs-button[md-raised-button] {
  text-transform: uppercase;
}

h1, h2 {
  font-weight: 400;
}

.docs-primary-header {
  padding-left: 20px;

  h1 {
    font-weight: 300;
    margin: 0;
    padding: 28px 8px;
    font-size: 20px;
  }
}

// These styles are for controlling SVGs without using the ::ng-deep selector

.docs-component-category-list-card-image svg {
  width: 100%;
}

.docs-angular-logo svg {
  height: 26px;
  margin: 0 4px 3px 0;
  vertical-align: middle;
}

.docs-github-logo svg {
  height: 21px;
  margin: 0 7px 2px 0;
  vertical-align: middle;
}

code, pre {
  font-family: 'Roboto Mono', monospace;
}

code {
  font-size: 90%;
}

pre {
  font-size: 14px;
}

table { 
  width: 100%; 
 
  th { 
    color: rgba(0,0,0,.54); 
    font-size: 12px; 
    font-weight: 500; 
    height: 56px; 
  } 
 
  th[align=""], 
  th:not([align]) { 
    text-align: left; 
  } 
 
  td { 
    font-size: 15px; 
    height: 48px; 
  } 
 
  td, 
  th { 
    padding: 0; 
    border-bottom-width: 1px; 
    border-bottom-style: solid; 
    border-bottom-color: rgba(0,0,0,.12); 
  } 
} 