// The values were determined through the combination of Material Design breakpoints and careful
// testing of the application across a range of common device widths (360px+).
// These breakpoint values need to stay in sync with the related constants in
// src/app/pages/component-sidenav/component-sidenav.ts.
// The extra small breakpoint is used for styling the guides and certain aspects of the tables.
$extra-small-breakpoint-width: 720px;
// The small breakpoint is used for the component category and component list pages, the component
// pages, the component sidenav, and certain aspects of the tables.
$small-breakpoint-width: 959px;

/* For desktop, the content should be aligned with the page title. */
$content-padding-guides-side: 70px;
$content-padding-sidenav: 50px;
$content-padding-side-xs: 15px;

$sidenav-width: 240px;
